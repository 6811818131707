/*
 * @Author: wron
 * @Date: 2022-04-12 16:16:01
 * @LastEditTime: 2022-04-13 15:59:54
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/lib/apply-use.js
 */
// 应用菜单注册

import { Get, Post } from "@lib/request";
import { Base } from "./config";

/**
 * 删除应用的菜单
 */
export const delAppMenus = (data) =>
  Post(Base + "/application/delAppMenus", data);

/**
 * 新增应用的菜单
 */
export const addAppMenu = (data) =>
  Post(Base + "/application/addAppMenu", data);


/**
 * 保存应用的菜单
 */
export const saveAppMenu = (data) =>
  Post(Base + "/application/saveAppMenu", data);


/**
 * 获取应用菜单
 */
export const getAppMenus = (data) =>
  Get(Base + "/application/getAppMenus", data);
