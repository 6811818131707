/*
 * @Author: your name
 * @Date: 2022-04-11 14:00:24
 * @LastEditTime: 2022-04-26 10:42:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/view/partner/apply/applyList/index.js
 */
import { getAppMenus, delAppMenus } from "@lib/apply-menu";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      codeId: "",
      fullName: "",
    };
  },
  methods: {
    stateFormatter(e) {
      return {
        0: "显示",
        1: "不显示",
      }[e.menuShow];
    },
    stateFormatterColor({ menuShow }) {
      return {
        0: "#04E01A",
        1: "#A30014",
      }[menuShow];
    },
    // 新增菜单 | 查看
    addAccount(info, editOrSee) {
      sessionStorage.setItem(
        "applyMenuAdd",
        JSON.stringify(this.tableData.length ? this.tableData : "")
      );
      if (info == undefined) {
        this.$router.push({
          name: "applyMenuAdd",
          params: { info: undefined },
        });
      } else {
        /* this.$confirm("修改应用后需重新审核，确定要修改？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => { */
        this.$router.push({
          name: "applyMenuAdd",
          params: { info: JSON.stringify(info), editOrSee },
        });
        /*  })
          .catch(() => {}); */
      }
    },
    //设置列表
    setList(list, result) {
      return list.map((item) => {
        item.p = [];
        const children = this.initCascader(
          result.filter((item) => item.pid),
          "pid",
          item.id,
          item.p
        );
        if (children.length) {
          item.children = children;
        }
        item.label = item.name;
        item.value = item.id;
        return item;
      });
    },
    //获取列表
    async getListFuction() {
      const { result } = await getAppMenus({ appId: this.codeId });
      this.tableData = this.setList(
        result.filter((item) => !item.pid || item.pid==='ROOT'),
        result
      );
    },
    async addAccountDel(val) {
      this.$confirm("确定删除菜单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delAppMenus({ ids: [val.id] }).then((res) => {
            if (res.status === "success") {
              this.getListFuction();
              this.$message.success("删除成功");
            }
          });
        })
        .catch(() => {});
    },
    jieRu(val) {
      this.$router.push({
        name: "applyLeader",
        params: { info: JSON.stringify(val) },
      });
    },
  },
  mounted() {
    const info = sessionStorage.getItem("applyMenu");
    const { code, fullName } = JSON.parse(info);
    this.codeId = code;
    this.fullName = fullName;
    this.getListFuction();
    this.sessionStorageRemove(["applyMenuAdd"]);
  },
  created() {
    let applyPage = sessionStorage.getItem("applyPage");
    if (applyPage) {
      this.page = Number(applyPage);
    }
  },
  beforeRouteEnter: (to, from, next) => {
    if (
      from.name != "applyAdd" &&
      from.name != "applyLeader" &&
      from.name != null
    ) {
      sessionStorage.removeItem("applyPage");
    }
    next();
  },
};
